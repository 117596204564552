"use client";

import { type UpdateListWorkflowRequest } from "@/app/api/list/workflow/route";
import { type SearchWorkflowResponse } from "@/app/api/workflows/route";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { LoadingIcon } from "@/components/ui/loading-icon";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { toast } from "@/components/ui/use-toast";
import { type List, type Workflow } from "@prisma/client";
import { LightningBoltIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import { useEffect, useState } from "react";

export const WorkflowControlIcon = ({ list }: { list: List }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [currentWorkflow, setCurrentListWorkflow] = useState<
    Workflow | undefined
  >(undefined);
  const [updateWorkflow, setUpdateWorkflow] = useState<Workflow | undefined>();

  useEffect(() => {
    setWorkflows([]);

    // Refetch list ids
    void fetch("/api/workflows", {
      method: "POST",
      body: JSON.stringify({} as SearchWorkflowResponse),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json() as Promise<SearchWorkflowResponse>)
      .then((workflow) => {
        setIsLoading(false);
        setWorkflows(workflow.workflows);
        if (list.workflowId) {
          const currentWorkflow = workflow.workflows.find(
            (w) => w.id === list.workflowId
          );
          setCurrentListWorkflow(currentWorkflow);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  function updateListWorkflow(clear = false) {
    if (!updateWorkflow) return;
    setIsUpdating(true);

    void fetch("/api/list/workflow", {
      method: "POST",
      body: JSON.stringify({
        id: list.id,
        workflowId: updateWorkflow.id,
        clear: clear,
      } as UpdateListWorkflowRequest),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res.ok) {
          if (clear) {
            setCurrentListWorkflow(undefined);
          } else {
            setCurrentListWorkflow(updateWorkflow);
          }
          toast({ title: "Workflow Updated" });
          window?.location?.reload();
        } else {
          toast({
            title: "Something went wrong",
            variant: "destructive",
            description: "Please try again",
          });
        }
      })
      .finally(() => {
        setIsUpdating(false);
      });
  }

  if (isLoading) {
    return null;
  }
  const hasChanged =
    updateWorkflow && updateWorkflow?.id !== currentWorkflow?.id;

  return (
    <Dialog>
      <DialogTrigger asChild>
        <LightningBoltIcon
          className={
            currentWorkflow
              ? "text-yellow-500 hover:text-yellow-600 transition-colors"
              : "text-gray-400 hover:text-gray-500 transition-colors"
          }
        />
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>Manage Workflow</DialogTitle>
        {currentWorkflow ? (
          <div className="flex flex-row gap-lg items-center">
            <Label className="text-gray-600">Current Workflow</Label>
            <Link
              href={`/dashboard/workflow/${currentWorkflow.id}`}
              target="__blank"
            >
              <Button variant="link">{currentWorkflow.name}</Button>
            </Link>
          </div>
        ) : (
          <Label className="text-gray-600">No Workflow For This List</Label>
        )}

        <div className="grid grid-cols-2">
          <Label>Update Workflow</Label>
          <Select
            value={updateWorkflow?.id ?? currentWorkflow?.id}
            onValueChange={(value) => {
              setUpdateWorkflow(workflows.find((w) => w.id === value));
            }}
          >
            <SelectTrigger
              className={hasChanged ? "text-brand-500" : undefined}
            >
              <SelectValue placeholder="Update New Workflow" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {workflows.map((workflow) => (
                  <SelectItem key={workflow.id} value={workflow.id}>
                    {workflow.name}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div className="flex flex-row justify-between">
          <div>
            <Button
              variant={"outlineDestructive"}
              onClick={() => updateListWorkflow(true)}
            >
              {isUpdating ? <LoadingIcon /> : "Remove Workflow"}
            </Button>
          </div>
          <div>
            <Button onClick={() => updateListWorkflow(false)}>
              {isUpdating ? <LoadingIcon /> : "Update Workflow"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

"use client";

import { NavBlockerLink } from "@/components/functional/nav-blocker-link";

import GearIcon from "@/components/icons/gear-icon";
import ListIcon from "@/components/icons/list-icon";
import SequenceIcon from "@/components/icons/sequence-icon";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { type Subscriptions } from "@prisma/client";
import { usePathname } from "next/navigation";

function SideBarButton({
  isSelected,
  label,
  link,
  icon,
}: {
  isSelected: boolean;
  label: string;
  link: string;
  icon: React.ReactNode;
}) {
  return (
    <Button
      size={"sm"}
      variant={isSelected ? "brand" : "ghost"}
      className={
        "block w-[165px] pl-xl justify-start pt-sm pb-base items-center rounded-r-full "
      }
    >
      <NavBlockerLink
        href={link}
        className={
          "flex " + (isSelected ? "text-white" : "text-text-secondary")
        }
      >
        <div className="w-[18px] h-[18px]">{icon}</div>
        <div className="ml-base text-sm not-italic font-medium leading-[140%]">
          {label}
        </div>
      </NavBlockerLink>
    </Button>
  );
}

function SectionHeader({ text }: { text: string }) {
  return (
    <div className="flex items-start self-stretch ml-xl text-text-inactive">
      <span className="text-xs not-italic font-[525] leading-[140%]">
        {text}
      </span>
    </div>
  );
}

export function Sidebar({ subscription }: { subscription: Subscriptions }) {
  const path = usePathname();

  const simplyUI = subscription.simpleUI;

  return (
    <div className="inline-flex absolute h-screen w-[180px] justify-between border-r bg-background-primary flex-shrink-0">
      <div className="py-2xl">
        <h2 className="mb-base px-xl text-lg font-semibold tracking-tight">
          <span className="text-primary text-2xl not-italic font-extrabold leading-6 tracking-[-3.6px]">
            Lava
          </span>
          <span className="text-surface-brand text-2xl not-italic font-extrabold leading-6 tracking-[-3.6px]">
            Reach
          </span>
        </h2>
        <Badge className="ml-xl mb-xl" variant={"brandhighlight"}>
          Alpha 0.0.1
        </Badge>
        <div className="h-3xl" />

        <SectionHeader text="Enrich Leads" />

        <div className="py-base pb-3xl">
          <SideBarButton
            isSelected={path === "/dashboard/lists"}
            label={"Lists"}
            link="/dashboard/lists"
            icon={<ListIcon />}
          />
        </div>

        {!simplyUI && (
          <>
            <SectionHeader text="Reach Leads" />

            <div className="py-base pb-3xl">
              <SideBarButton
                isSelected={path === "/dashboard/sequences"}
                label={"Sequences"}
                link="/dashboard/sequences"
                icon={<SequenceIcon />}
              />
            </div>
          </>
        )}

        {/* {!simplyUI && (
          <>
            <SectionHeader text="Track Leads" />
            <div className="py-base pb-3xl">
              <SideBarButton
                isSelected={
                  path?.startsWith("/dashboard/monitor-jobs") ?? false
                }
                label={"Jobs Changes"}
                link="/dashboard/monitor-jobs"
                icon={<EyeIcon />}
              />

              {enableWorkflow && (
                <SideBarButton
                  isSelected={path?.startsWith("/dashboard/workflow") ?? false}
                  label={"Workflows"}
                  link="/dashboard/workflows"
                  icon={<WorkflowIcon />}
                />
              )}
            </div>
          </>
        )} */}
        <div className="absolute bottom-0">
          <SectionHeader text="Account" />

          <div className="py-base pb-2xl">
            <SideBarButton
              isSelected={
                (path?.startsWith("/dashboard/settings") ?? false) &&
                path !== "/dashboard/settings/integrations"
              }
              label={"Settings"}
              link="/dashboard/settings"
              icon={<GearIcon />}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
